module.exports = {
  PAGE_LOADER: "PAGE_LOADER",
  BOOK_NOW_SUBMIT_SUCCESS: "BOOK_NOW_SUBMIT_SUCCESS",
  BOOK_NOW_SUBMIT_FAILURE: "BOOK_NOW_SUBMIT_FAILURE",
  GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
  GET_ORDER_DETAILS_FAILURE: "GET_ORDER_DETAILS_FAILURE",
  GREEN_CHAMP_SIGNUP_SUCCESS: "GREEN_CHAMP_SIGNUP_SUCCESS",
  GREEN_CHAMP_SIGNUP_FAILURE: "GREEN_CHAMP_SIGNUP_FAILURE",
  DEALER_LOGIN_SUCCESS: "DEALER_LOGIN_SUCCESS",
  DEALER_LOGIN_FAILURE: "DEALER_LOGIN_FAILURE",
  GET_ACCOSORIES_BY_COUPON: "GET_ACCOSORIES_BY_COUPON",
  GET_ACCOSORIES_BY_COUPON_FAILED: "GET_ACCOSORIES_BY_COUPON_FAILED",
  SUBSCRIBE_SUCCESS: "SUBSCRIBE_SUCCESS",
  SUBSCRIBE_FAILURE: "SUBSCRIBE_FAILURE",
};
