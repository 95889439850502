import React from "react";
import classes from "./store.module.css";
import Section1 from "./section1";
import { Typography } from "@mui/material";

const StoreIndex = () => {
  return (
    <div className={classes.StoreMainContainer}>
      <Typography className={classes.StoreFloatText1}>EXPERIENCE</Typography>
      <Typography
        className={`${classes.StoreFloatText1} ${classes.StoreFloatText2}`}
      >
        CENTER
      </Typography>
      <Section1 />
    </div>
  );
};

export default StoreIndex;
