module.exports = {
  GET_ALL_CAREERS_LIST_SUCCESS: "GET_ALL_CAREERS_LIST_SUCCESS",
  GET_ALL_CAREERS_LIST_FAILED: "GET_ALL_CAREERS_LIST_FAILED",
  GET_ONE_CAREER_DETAILS_SUCCESS: "GET_ONE_CAREER_DETAILS_SUCCESS",
  GET_ONE_CAREER_DETAILS_FAILED: "GET_ONE_CAREER_DETAILS_FAILED",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILED: "UPLOAD_IMAGE_FAILED",
  APPLY_JOB_SUCCESS: "APPLY_JOB_SUCCESS",
  APPLY_JOB_FAILED: "APPLY_JOB_FAILED",
};
