import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./Layout/defaultLayout";
import Home from "./Pages/Home/home";
import AboutUs from "./Pages/AboutUs/aboutUs";
import StoreIndex from "./Pages/store";
import PranaIndex from "./Pages/Prana";
import DemoDriveIndex from "./Pages/Demodrive";
import VisitUsIndex from "./Pages/Visitus";
import GuestLayout from "./Layout/guestLayout";
import Login from "./Pages/Auth/login";
import GreenChapSignup from "./Pages/Auth/greenChapSignup";
import BlogsPage from "./Pages/Others/blogsPage";
import EventsPage from "./Pages/Others/eventsPage";
import BooknowIndex from "./Pages/Booknow";
import AddressDetails from "./Pages/Booknow/addressDetails";
import PaymentMethod from "./Pages/Booknow/paymentMethod";
import TermsandConditions from "./Pages/Others/termsandConditions";
import Policy from "./Pages/Others/policy";
import Faq from "./Pages/Others/Faq";
import RefundPolicy from "./Pages/Others/refund";
import Index404 from "./Pages/404";
import BecomeLeader from "./Pages/Others/becomeLeader";
import NewsViewPage from "./Pages/Others/newsViewPage";
import TcoPage from "./Pages/Others/tco";
import CareerIndex from "./Pages/Career";
import CareerApplyform from "./Pages/Career/careerApplyform";
import ViewCareer from "./Pages/Career/viewCareer";
import MyOrders from "./Pages/Home/MyOrders";
import CookiePolicy from "./Pages/Others/CookiePolicy";
import BlogViewPage from "./Pages/Others/BlogViewPage";
import BlogPageNew from "./Pages/Others/BlogPageNew";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/stores",
        element: <StoreIndex />,
      },
      {
        path: "/prana",
        element: <PranaIndex />,
      },
      {
        path: "/demo-drive",
        element: <DemoDriveIndex />,
      },
      {
        path: "/contact-us",
        element: <VisitUsIndex />,
      },
      {
        path: "/blogs",
        element: <BlogPageNew />,
      },
      {
        path: "/blog/:id",
        element: <BlogViewPage />,
      },
      {
        path: "/career",
        element: <CareerIndex />,
      },
      {
        path: "/career/:id",
        element: <ViewCareer />,
      },
      {
        path: "/career/apply-form/:id",
        element: <CareerApplyform />,
      },
      {
        path: "/news",
        element: <EventsPage />,
      },
      {
        path: "/address-details",
        element: <BooknowIndex />,
      },
      {
        path: "/signup",
        element: <GreenChapSignup />,
      },
      {
        path: "/terms-condition",
        element: <TermsandConditions />,
      },
      {
        path: "/cookie-policies",
        element: <CookiePolicy />,
      },
      {
        path: "/policy",
        element: <Policy />,
      },
      {
        path: "/refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/tco",
        element: <TcoPage />,
      },
      {
        path: "/news/:id",
        element: <NewsViewPage />,
      },
      {
        path: "/become-dealer",
        element: <BecomeLeader />,
      },
      {
        path: "/myorders",
        element: <MyOrders />,
      },
      {
        path: "*",
        element: <Index404 />,
      },
    ],
  },
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/book-now",
        element: <BooknowIndex />,
      },
      {
        path: "/order-summary",
        element: <BooknowIndex />,
      },
      {
        path: "/payment-method",
        element: <BooknowIndex />,
      },
    ],
  },
]);

export default Router;
