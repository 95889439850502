import React, { Suspense, useEffect, useState } from "react";
import classes from "./home.module.css";
import { Typography } from "@mui/material";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  GetBikeVariants,
  getAllPartnersList,
  getAllTestimonials,
  getBannerImagedatas,
} from "../../Redux/Actions/homeActions";
import { getCategoriesList } from "../../Redux/Actions/demoDriveActions";
import { getAllNewsList } from "../../Redux/Actions/otherActions";

const BannerComponent = React.lazy(() => import("./banner"));
const Section2 = React.lazy(() => import("./section2"));
const Section3 = React.lazy(() => import("./section3"));
const Section4 = React.lazy(() => import("./section4"));
const Section5 = React.lazy(() => import("./section5"));
const Section6 = React.lazy(() => import("./section6"));
const Section7 = React.lazy(() => import("./Section7"));
const Section8 = React.lazy(() => import("./section8"));
const Section9 = React.lazy(() => import("./section9"));
const Section10 = React.lazy(() => import("./section10"));
const Section11 = React.lazy(() => import("./section11"));
const Section12 = React.lazy(() => import("./section12"));

const Home = () => {
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const { bannerData, error } = useSelector((state) => state.homeDatas);
  const { loading } = useSelector((state) => state.userLogin);
  const [loadState, setLoadState] = useState(true);

  const fetchData = async () => {
    dispatch(pageLoader(true));
    dispatch(getBannerImagedatas(setErrorAlert));
    dispatch(GetBikeVariants(setErrorAlert));
    dispatch(getAllPartnersList(setErrorAlert));
    dispatch(getAllTestimonials(setErrorAlert));
    dispatch(getCategoriesList(setErrorAlert));
    dispatch(getAllNewsList(setErrorAlert));
  };

  const debouncedFetchData = debounce(fetchData, 500); // Adjust the delay as needed

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setLoadState(loading);
  }, []);

  return (
    <div>
      <div className={classes.homeContainer}>
        <Suspense fallback={<div>Loading...</div>}>
          <BannerComponent />
        </Suspense>
        {!loading && bannerData && bannerData.length > 0 && (
          <>
            <div className={classes.section2Container}>
              <Suspense fallback={<div>Loading...</div>}>
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Section6 />
                <Section7 />
              </Suspense>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <Section8 />
              <Section9 />
              <Section10 />
            </Suspense>
            <div className={classes.section2Container}>
              <Suspense fallback={<div>Loading...</div>}>
                <Section11 />
                <Section12 />
              </Suspense>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
