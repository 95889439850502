import React from "react";
import classes from "./demoDrive.module.css";
import Section1 from "./section1";
import Section2 from "./section2";
import { Typography } from "@mui/material";

const DemoDriveIndex = () => {
  return (
    <div className={classes.demoDriveMainContainer}>
      <Typography className={classes.StoreFloatText1}>DEMO</Typography>
      <Typography
        className={`${classes.StoreFloatText1} ${classes.StoreFloatText2}`}
      >
        RIDE
      </Typography>
      <Section1 />
      <Section2 />
    </div>
  );
};

export default DemoDriveIndex;
