import React, { useEffect, useState } from "react";
import classes from "./bookNow.module.css";
import { Row, Col } from "react-bootstrap";
import bikeImage from "../../Assets/Images/blackBike.png";
import BookNowSignupForm from "./booknowSignupForm";
import AddressDetails from "./addressDetails";
import backgroundImage from "../../Assets/Images/bannerBike.png";
import meterImage1 from "../../Assets/Images/meterImage1.png";
import meterImage2 from "../../Assets/Images/meterImage2.png";
import meterImage3 from "../../Assets/Images/meterImage3.png";
import { Typography } from "@mui/material";
import OrderSummary from "./orderSummary";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  getCategoriesList,
  getStoreList,
} from "../../Redux/Actions/demoDriveActions";
import { GetBikeVariants } from "../../Redux/Actions/homeActions";
import PaymentMethod from "./paymentMethod";
import { BatteryIcon } from "../../Assets/Icons/icons";

const BooknowIndex = () => {
  const dispatch = useDispatch();
  const { error, success, storeList, categoryList } = useSelector(
    (state) => state.demoDriveDatas
  );
  const navigate = useNavigate();
  const { bikeVariants } = useSelector((state) => state.homeDatas);
  const [errorAlert, setErrorAlert] = useState(false);
  const [selectedVartiant, setSelectedVartiant] = useState(null);

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getStoreList(setErrorAlert, "showroom", ""));
    dispatch(GetBikeVariants(setErrorAlert));
    dispatch(getCategoriesList(setErrorAlert));
    dispatch(pageLoader(false));
  }, []);

  const selectedColor = (item) => {
    console.log("item", item);
    setSelectedVartiant(item);
  };

  return (
    <div className={classes.BooknowIndexMaindiv}>
      {/* <img
        src={
          selectedVartiant
            ? `${process.env.REACT_APP_API_URL}${selectedVartiant.image}`
            : `${process.env.REACT_APP_API_URL}${bikeVariants[0].image}`
        }
        alt=""
        className={classes.BooknowOpacityBanner}
      /> */}
      <div className={classes.BooknowIndexRow}>
        <div className={classes.LeftBikeContainer}>
          <img
            src={
              selectedVartiant
                ? `${process.env.REACT_APP_API_URL}${selectedVartiant?.image}`
                : `${process.env.REACT_APP_API_URL}${bikeVariants[0]?.image}`
            }
            alt=""
            className={classes.likeContainerBike}
          />
          <div className={classes.contentRowContainer}>
            <div className={classes.contentColumnContainer}>
              <img
                src={meterImage1}
                alt="meterImage1"
                className={classes.meterImage}
              />
              <div>
                <Typography className={classes.meterHeaderText}>
                  {selectedVartiant
                    ? selectedVartiant?.certified_range
                    : bikeVariants[0]?.certified_range}{" "}
                  km*
                </Typography>
                <Typography className={classes.meterSubText}>
                  Real World Range
                </Typography>
              </div>
            </div>
            <div className={classes.contentColumnContainer}>
              {/* <img
                src={meterImage2}
                alt="meterImage2"
                className={classes.meterImage}
              /> */}
              <span className={classes.batteryIcon}>
                <BatteryIcon />
              </span>
              <div>
                <Typography className={classes.meterHeaderText}>
                  {selectedVartiant
                    ? selectedVartiant?.battery_capacity
                    : bikeVariants[0]?.battery_capacity}{" "}
                  Kwh
                </Typography>
                <Typography className={classes.meterSubText}>
                  Battery Capacity
                </Typography>
              </div>
            </div>
            <div className={classes.contentColumnContainer}>
              <img
                src={meterImage3}
                alt="meterImage3"
                className={classes.meterImage}
              />
              <div>
                <Typography className={classes.meterHeaderText}>
                  {selectedVartiant
                    ? selectedVartiant?.topSpeed
                    : bikeVariants[0]?.topSpeed}{" "}
                  kmph
                </Typography>
                <Typography className={classes.meterSubText}>
                  Top Speed
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.RightBikeContainer}>
          {window.location.pathname.includes("/book-now") ? (
            <BookNowSignupForm SelectedVartiant={selectedColor} />
          ) : window.location.pathname.includes("/payment-method") ? (
            <PaymentMethod SelectedVartiant={selectedColor} />
          ) : (
            <OrderSummary />
          )}
        </div>
      </div>
    </div>
  );
};

export default BooknowIndex;
