module.exports = {
  GET_EVENTS_LIST_SUCCESS: "GET_EVENTS_LIST_SUCCESS",
  GET_EVENTS_LIST_FAILED: "GET_EVENTS_LIST_FAILED",
  GET_NEWS_LIST_SUCCESS: "GET_NEWS_LIST_SUCCESS",
  GET_NEWS_LIST_FAILED: "GET_NEWS_LIST_FAILED",
  GET_BLOGS_LIST_SUCCESS: "GET_BLOGS_LIST_SUCCESS",
  GET_BLOGS_LIST_FAILED: "GET_BLOGS_LIST_FAILED",
  GET_ORDER_DATAS_SUCCESS: "GET_ORDER_DATAS_SUCCESS",
  GET_ORDER_DATAS_FAILED: "GET_ORDER_DATAS_FAILED",
  ADD_CUSTOMER_DELEVIERY_ADDRESS_SUCCESS:
    "ADD_CUSTOMER_DELEVIERY_ADDRESS_SUCCESS",
  ADD_CUSTOMER_DELEVIERY_ADDRESS_FAILED:
    "ADD_CUSTOMER_DELEVIERY_ADDRESS_FAILED",
  GET_TERMS_CONDITION_SUCCESS: "GET_TERMS_CONDITION_SUCCESS",
  GET_TERMS_CONDITION_FAILED: "GET_TERMS_CONDITION_FAILED",
  GET_FAQ_LIST_SUCCESS: "GET_FAQ_LIST_SUCCESS",
  GET_FAQ_LIST_FAILED: "GET_FAQ_LIST_FAILED",
  GET_ONE_NEWS_DETAILS_SUCCESS: "GET_ONE_NEWS_DETAILS_SUCCESS",
  GET_ONE_NEWS_DETAILS_FAILED: "GET_ONE_NEWS_DETAILS_FAILED",
  GET_ONE_BLOG_DETAILS_SUCCESS: "GET_ONE_BLOG_DETAILS_SUCCESS",
  GET_ONE_BLOG_DETAILS_FAILED: "GET_ONE_BLOG_DETAILS_FAILED",
  TCO_BIKES_LIST_SUCCESS: "TCO_BIKES_LIST_SUCCESS",
  TCO_BIKES_LIST_FAILED: "TCO_BIKES_LIST_FAILED",
  ALL_STATES_SUCCESS: "ALL_STATES_SUCCESS",
  ALL_STATES_FAILED: "ALL_STATES_FAILED",
  ALL_CITY_SUCCESS: "ALL_CITY_SUCCESS",
  ALL_CITY_FAILED: "ALL_CITY_FAILED",
};
