module.exports = {
    GET_CATEGORY_LIST_SUCCESS: "GET_CATEGORY_LIST_SUCCESS",
    GET_CATEGORY_LIST_FAILURE: "GET_CATEGORY_LIST_FAILURE",
    SUBMIT_DEMODRIVE_SUCCESS: "SUBMIT_DEMODRIVE_SUCCESS",
    SUBMIT_DEMODRIVE_FAILURE: "SUBMIT_DEMODRIVE_FAILURE",
    GET_STORE_LIST_SUCCESS: "GET_STORE_LIST_SUCCESS",
    GET_STORE_LIST_FAILURE: "GET_STORE_LIST_FAILURE",
    SUBMIT_VISIT_US_SUCCESS: "SUBMIT_VISIT_US_SUCCESS",
    SUBMIT_VISIT_US_FAILURE: "SUBMIT_VISIT_US_FAILURE",
    GET_SHOWROOM_LIST_SUCCESS: "GET_SHOWROOM_LIST_SUCCESS",
    GET_SHOWROOM_LIST_FAILURE: "GET_SHOWROOM_LIST_FAILURE",
    GET_CITIES_LIST_SUCCESS: "GET_CITIES_LIST_SUCCESS",
    GET_CITIES_LIST_FAILURE: "GET_CITIES_LIST_FAILURE",
    GET_ACCESSORIES_LIST_SUCCESS: "GET_ACCESSORIES_LIST_SUCCESS",
    GET_ACCESSORIES_LIST_FAILURE: "GET_ACCESSORIES_LIST_FAILURE",
}