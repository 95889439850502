import React from "react";
import AboutBanner from "./aboutBanner";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const { aboutUsBannerImage, error } = useSelector(
    (state) => state.aboutUsDatas
  );
  const { loading } = useSelector((state) => state.userLogin);

  return (
    <div>
      <Helmet htmlAttributes>
        <meta
          name="description"
          content="SVM is dedicated to designing and manufacturing premium electric motorbikes in India. We revolutionize the two-wheeler vehicles and propel the industry forward by leveraging sustainable energy solutions without compromising the quality of the rider’s experience."
        />
        <meta property="og:url" content="https://srivarumotors.com/about-us" />
        <meta
          property="og:description"
          content="SVM is dedicated to designing and manufacturing premium electric motorbikes in India. We revolutionize the two-wheeler vehicles and propel the industry forward by leveraging sustainable energy solutions without compromising the quality of the rider’s experience."
        />
        <meta
          property="twitter:url"
          content="https://srivarumotors.com/about-us"
        />
        <meta
          property="twitter:description"
          content="SVM is dedicated to designing and manufacturing premium electric motorbikes in India. We revolutionize the two-wheeler vehicles and propel the industry forward by leveraging sustainable energy solutions without compromising the quality of the rider’s experience."
        />
      </Helmet>
      <AboutBanner />
      {!loading && aboutUsBannerImage && aboutUsBannerImage.length > 0 && (
        <>
          <Section2 />
          <Section3 />
          <Section4 />
        </>
      )}
    </div>
  );
};

export default AboutUs;
