import { Typography, Button, Card } from "@material-ui/core";
import classes from "./bookNow.module.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, submitOtp } from "../../Redux/Actions/userActions";
import { GetOrderDatas } from "../../Redux/Actions/otherActions";
import { Row, Col } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import OtpModel from "./OtpModel";
import { getOtp } from "../../Redux/Actions/aboutUsActions";
import { Table, TableCell, TableRow } from "@mui/material";

const OrderSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [otpModalToggle, setOtpModalToggle] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { orderDetails, error } = useSelector((state) => state.otherDatas);
  const userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(GetOrderDatas(setErrorAlert, userData._id));
    dispatch(pageLoader(false));
  }, []);

  // useEffect(() => {}, [orderDetails]);

  const hanldeClickContinue = () => {
    setIsClicked(true);
    setTimeout(() => {
      handleSubmit();
      setIsClicked(false);
    }, 1000);
  };

  const handleOtpSubmit = (otp) => {
    const userEntry = {
      otp: otp,
      phone: userData?.phone,
      type: "order",
    };
    dispatch(pageLoader(true));
    dispatch(
      submitOtp(
        setErrorAlert,
        userEntry,
        navigate,
        "/order-summary",
        setShowSuccessModal
      )
    );
    dispatch(pageLoader(false));
    setOtpModalToggle(false);
  };

  const handleSubmit = () => {
    const userEntry = {
      phone: orderDetails?.customerDetails.phone,
    };
    dispatch(pageLoader(true));
    dispatch(getOtp(setErrorAlert, userEntry, setOtpModalToggle));
    dispatch(pageLoader(false));
  };

  const modalClose = () => {
    setOtpModalToggle(false);
    setShowSuccessModal(false);
  };

  const navigateFunction = () => {
    console.log("navigate");
    setShowSuccessModal(false);
    navigate("/");
  };

  return (
    <div className={classes.formInputContainer}>
      <Typography className={classes.orderText}>
        You're one step closer to owning the future. Please pay your booking
        amount to reserve
      </Typography>
      <Typography className={classes.orderText}>
        your chosen {orderDetails?.categoriesDetails?.title}. Click and select
        your preferred payment method.
      </Typography>
      <Card className={classes.paymentCardContainer}>
        <Table>
          <TableRow className={classes.orderSummaryTableRow}>
            <TableCell className={classes.OrderSummaryFirstTableCell}>
              Name
            </TableCell>
            <TableCell className={classes.OrderSummarySecondTableCell}>
              {orderDetails?.customerDetails?.name}
            </TableCell>
          </TableRow>
          <TableRow className={classes.orderSummaryTableRow}>
            <TableCell className={classes.OrderSummaryFirstTableCell}>
              Email Id
            </TableCell>
            <TableCell className={classes.OrderSummarySecondTableCell}>
              {orderDetails?.customerDetails?.email}
            </TableCell>
          </TableRow>
          <TableRow className={classes.orderSummaryTableRow}>
            <TableCell className={classes.OrderSummaryFirstTableCell}>
              Mobile
            </TableCell>
            <TableCell className={classes.OrderSummarySecondTableCell}>
              {orderDetails?.customerDetails?.phone}
            </TableCell>
          </TableRow>
          <TableRow className={classes.orderSummaryTableRow}>
            <TableCell className={classes.OrderSummaryFirstTableCell}>
              Bike Model | Color
            </TableCell>
            <TableCell
              className={classes.OrderSummarySecondTableCell}
              style={{ textTransform: "capitalize" }}
            >
              {orderDetails?.categoriesDetails?.title} |{" "}
              {orderDetails?.bikeVariantDetails?.colorName.toLowerCase()}
            </TableCell>
          </TableRow>
          <TableRow className={classes.orderSummaryTableRow}>
            <TableCell className={classes.OrderSummaryFirstTableCell}>
              Booking Amount
            </TableCell>
            <TableCell
              className={classes.OrderSummarySecondTableCell}
              style={{ textTransform: "capitalize" }}
            >
              ₹ {orderDetails?.order?.booking_amount}
            </TableCell>
          </TableRow>
          <TableRow className={classes.orderSummaryTableRow}>
            <TableCell className={classes.OrderSummaryFirstTableCell}>
              Dealer Hub
            </TableCell>
            <TableCell
              className={classes.OrderSummarySecondTableCell}
              style={{ textTransform: "capitalize" }}
            >
              {orderDetails?.dealer_hub ? orderDetails?.dealer_hub : "N/A"}
            </TableCell>
          </TableRow>
        </Table>
      </Card>
      <div className={classes.BooknowSubmitBtnContainer}>
        <span
          className={`${classes.BookNowContinueIcon} ${
            isClicked && classes.arrowTransist
          }`}
        >
          <FaArrowRight />
        </span>
        <Button
          variant="outlined"
          className={`${classes.BookNowBtn} ${
            isClicked && classes.BtnTransist
          }`}
          onClick={hanldeClickContinue}
        >
          <span>Continue</span>
        </Button>
      </div>
      {otpModalToggle && (
        <OtpModel modalClose={modalClose} callback={handleOtpSubmit} />
      )}
      {showSuccessModal && (
        <OtpModel modalClose={navigateFunction} type="success" />
      )}
    </div>
  );
};

export default OrderSummary;
