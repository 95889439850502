import React, { useEffect, useState } from "react";
import classes from "./visitUs.module.css";
import Section1 from "./section1";
import Section2 from "./section2";
import { useDispatch } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import { getStoreList } from "../../Redux/Actions/demoDriveActions";
import { Typography } from "@mui/material";

const VisitUsIndex = () => {
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getStoreList(setErrorAlert, "showroom", ""));
    dispatch(pageLoader(false));
  });

  return (
    <div className={classes.VisitUsMainContainer}>
      <Typography className={classes.StoreFloatText1}>CONTACT</Typography>
      <Typography
        className={`${classes.StoreFloatText1} ${classes.StoreFloatText2}`}
      >
        US
      </Typography>
      <Section1 />
      {/* <Section2 /> */}
    </div>
  );
};

export default VisitUsIndex;
