import React, { useEffect, useState } from "react";
import { BiMessageDetail } from "react-icons/bi";
import classes from "./layout.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Drawer } from "@mui/material";
import { CloseIcon, EnquiryIcon, ProfileIcon } from "../Assets/Icons/icons";
import { BsPerson } from "react-icons/bs";
import logo from "../Assets/Images/Logo.png";
import { Nav, Navbar } from "react-bootstrap";
import EnquiryForm from "../Pages/Others/enquiryForm";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  const [loginStatus, setLoginStatus] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [modelToggle, setModelToggle] = useState(false);
  const handleNavigate = (name, route) => {
    // dispatch(setHeaderName(name));
    setCurrentRoute(route);
    if (window.innerWidth < 850) {
    }
  };

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  useEffect(() => {
    console.log(window.location.pathname);
    const userData = localStorage.getItem("userData");
    if (!userData) {
      setLoginStatus(false);
    } else {
      setLoginStatus(true);
    }
  }, [window.location.pathname]);

  const modalClose = () => {
    setModelToggle(false);
  };

  const handleLoginstatus = () => {
    if (loginStatus) {
      setLoginStatus(false);
      localStorage.removeItem("userData");
      navigate("/");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    setCurrentRoute(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div>
      <div className={classes.headerMainDiv}>
        <div>
          <Link to="/" onClick={() => handleNavigate("home", "/")}>
            <img src={logo} alt="logo" className={classes.logo} />
          </Link>
        </div>
        <div>
          <ul className={classes.navbar}>
            <Link
              to="/"
              onClick={() => handleNavigate("home", "/")}
              className={currentRoute === "/" ? `${classes.activebtn}` : null}
            >
              <li>
                <Button variant="text" className={classes.linkburron}>
                  Home
                </Button>
              </li>
            </Link>
            <Link
              to="/about-us"
              onClick={() => handleNavigate("about-us", "/about-us")}
              className={
                currentRoute?.includes("about-us")
                  ? `${classes.activebtn}`
                  : null
              }
            >
              <li>
                <Button variant="text" className={classes.linkburron}>
                  About Us
                </Button>
              </li>
            </Link>
            <Link
              to="/prana"
              onClick={() => handleNavigate("prana", "/prana")}
              className={
                currentRoute?.includes("prana") ? `${classes.activebtn}` : null
              }
            >
              <li>
                <Button variant="text" className={classes.linkburron}>
                  Prana
                </Button>
              </li>
            </Link>
            <Link
              to="/stores"
              onClick={() => handleNavigate("store", "/stores")}
              className={
                currentRoute?.includes("stores") ? `${classes.activebtn}` : null
              }
            >
              <li>
                <Button variant="text" className={classes.linkburron}>
                  Experience Center
                </Button>
              </li>
            </Link>
            <Link
              to="/demo-drive"
              onClick={() => handleNavigate("demo-drive", "/demo-drive")}
              className={
                currentRoute?.includes("demo-drive")
                  ? `${classes.activebtn}`
                  : null
              }
            >
              <li>
                <Button variant="text" className={classes.linkburron}>
                  Demo Ride
                </Button>
              </li>
            </Link>
            <Link
              to="/contact-us"
              onClick={() => handleNavigate("contact-us", "/contact-us")}
              className={
                currentRoute?.includes("contact-us")
                  ? `${classes.activebtn}`
                  : null
              }
            >
              <li>
                <Button variant="text" className={classes.linkburron}>
                  Contact Us
                </Button>
              </li>
            </Link>
          </ul>
        </div>
        <div className={classes.headerButtonDiv}>
          <Button
            variant="contained"
            className={classes.loginButton}
            onClick={() => setModelToggle(true)}
          >
            <span>Enquiry</span>
            <BiMessageDetail />
          </Button>
          <Link
            onClick={() => handleLoginstatus()}
            className={
              currentRoute?.includes("login") ? `${classes.activebtn}` : null
            }
          >
            <Button variant="contained" className={classes.loginButton}>
              <BsPerson />
              <span>{loginStatus ? "Logout" : "Login"}</span>
            </Button>
          </Link>
          <Button
            variant="contained"
            className={`${classes.loginButton} ${classes.hamburgerMenuButton}}`}
            onClick={() => handleOpenDrawer()}
          >
            <GiHamburgerMenu />
          </Button>
        </div>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          <div className={classes.DrawerContainer}>
            <Button
              onClick={toggleDrawer(false)}
              className={classes.DrwercloseBtn}
            >
              <CloseIcon />
            </Button>
            <Link
              to="https://svmh.ai/"
              target="_blank"
              onClick={() => {
                handleNavigate("investor", "https://svmh.ai/");
              }}
              className={
                currentRoute?.includes("investor")
                  ? `${classes.activebtn}`
                  : null
              }
            >
              <Button
                onClick={toggleDrawer(false)}
                variant="text"
                className={`${classes.linkburron} ${classes.drawerLinks}`}
              >
                <span>Investor Relations</span>
              </Button>
            </Link>
            <Link
              to="/news"
              className={
                currentRoute?.includes("events") ? `${classes.activebtn}` : null
              }
            >
              <Button
                onClick={toggleDrawer(false)}
                variant="text"
                className={`${classes.linkburron} ${classes.drawerLinks}`}
              >
                <span>News & Events</span>
              </Button>
            </Link>
            <Link
              to="/blogs"
              className={
                currentRoute?.includes("blogs") ? `${classes.activebtn}` : null
              }
            >
              <Button
                onClick={toggleDrawer(false)}
                variant="text"
                className={`${classes.linkburron} ${classes.drawerLinks}`}
              >
                <span>Blogs</span>
              </Button>
            </Link>
            <Link
              to="#"
              className={
                currentRoute?.includes("careers")
                  ? `${classes.activebtn}`
                  : null
              }
            >
              <Button
                onClick={toggleDrawer(false)}
                variant="text"
                className={`${classes.linkburron} ${classes.drawerLinks}`}
              >
                <span>Careers</span>
              </Button>
            </Link>
            <Link
              to="/become-dealer"
              className={
                currentRoute?.includes("/become-dealer")
                  ? `${classes.activebtn}`
                  : null
              }
            >
              <Button
                onClick={toggleDrawer(false)}
                variant="text"
                className={`${classes.linkburron} ${classes.drawerLinks}`}
              >
                <span>Become a Dealer</span>
              </Button>
            </Link>
            <Link
              to="/faq"
              className={
                currentRoute?.includes("faq") ? `${classes.activebtn}` : null
              }
            >
              <Button
                onClick={toggleDrawer(false)}
                variant="text"
                className={`${classes.linkburron} ${classes.drawerLinks}`}
              >
                <span>FAQ</span>
              </Button>
            </Link>
            <Link
              to="/tco"
              className={
                currentRoute?.includes("tco") ? `${classes.activebtn}` : null
              }
            >
              <Button
                onClick={toggleDrawer(false)}
                variant="text"
                className={`${classes.linkburron} ${classes.drawerLinks}`}
              >
                <span>TCO</span>
              </Button>
            </Link>
            {userData && (
              <Link
                to="/myorders"
                className={
                  currentRoute?.includes("myorders")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <Button
                  onClick={toggleDrawer(false)}
                  variant="text"
                  className={`${classes.linkburron} ${classes.drawerLinks}`}
                >
                  <span>My Orders</span>
                </Button>
              </Link>
            )}
          </div>
        </Drawer>
      </div>
      <div className={classes.smallNavBarCotainer}>
        <Navbar
          collapseOnSelect
          expand="lg"
          // bg="light"
          // variant="light"
          style={{ padding: "10px 20px", background: "#F2F2F2" }}
        >
          <Navbar.Brand href="#home">
            <img src={logo} alt="logo" className={classes.logo} />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className={classes.MenuIcon}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={`mr-auto ${classes.navbarContainer}`}>
              <Nav.Link
                href="/"
                onClick={() => handleNavigate("home", "/")}
                className={currentRoute === "/" ? `${classes.activebtn}` : null}
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Home
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="/about-us"
                onClick={() => handleNavigate("about-us", "/about-us")}
                className={
                  currentRoute?.includes("about-us")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    About Us
                  </Button>
                </li>
              </Nav.Link>
              {/* </Nav>
            <Nav> */}
              <Nav.Link
                href="/prana"
                onClick={() => handleNavigate("prana", "/prana")}
                className={
                  currentRoute?.includes("prana")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Prana
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="/stores"
                onClick={() => handleNavigate("stores", "/stores")}
                className={
                  currentRoute?.includes("stores")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Experience Center
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="/demo-drive"
                onClick={() => handleNavigate("demo-drive", "/demo-drive")}
                className={
                  currentRoute?.includes("demo-drive")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Demo Ride
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="/contact-us"
                onClick={() => handleNavigate("contact-us", "/contact-us")}
                className={
                  currentRoute?.includes("contact-us")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Contact Us
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="/news"
                onClick={() => handleNavigate("news", "/news")}
                className={
                  currentRoute?.includes("news") ? `${classes.activebtn}` : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    News & Events
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="#"
                onClick={() => handleNavigate("blogs", "/blogs")}
                className={
                  currentRoute?.includes("blogs")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Blogs
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="#"
                onClick={() => handleNavigate("careers", "/careers")}
                className={
                  currentRoute?.includes("careers")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Careers
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="/become-dealer"
                onClick={() =>
                  handleNavigate("become-dealer", "/become-dealer")
                }
                className={
                  currentRoute?.includes("become-dealer")
                    ? `${classes.activebtn}`
                    : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Become A Dealer
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="/faq"
                onClick={() => handleNavigate("faq", "/faq")}
                className={
                  currentRoute?.includes("faq") ? `${classes.activebtn}` : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    FAQ
                  </Button>
                </li>
              </Nav.Link>
              <Nav.Link
                href="/tco"
                onClick={() => handleNavigate("tco", "/tco")}
                className={
                  currentRoute?.includes("tco") ? `${classes.activebtn}` : null
                }
              >
                <li>
                  <Button variant="text" className={classes.linkburron}>
                    Tco
                  </Button>
                </li>
              </Nav.Link>
              {userData && (
                <Nav.Link
                  href="/myorders"
                  onClick={() => handleNavigate("myorders", "/myorders")}
                  className={
                    currentRoute?.includes("myorders")
                      ? `${classes.activebtn}`
                      : null
                  }
                >
                  <li>
                    <Button variant="text" className={classes.linkburron}>
                      My Orders
                    </Button>
                  </li>
                </Nav.Link>
              )}
              {/* <Nav.Divider /> */}
              <Nav.Link href="https://svmh.ai/" target="_blank">
                <Button
                  variant="contained"
                  className={classes.loginButton}
                  onClick={() => handleNavigate("investor", "https://svmh.ai/")}
                >
                  <span>Investor Relations</span>
                </Button>
              </Nav.Link>
              <Nav.Link onClick={() => setModelToggle(true)}>
                <Button variant="contained" className={classes.loginButton}>
                  <span>Enquiry</span>
                  <BiMessageDetail />
                </Button>
              </Nav.Link>
              <Nav.Link>
                <Button
                  variant="contained"
                  className={classes.loginButton}
                  onClick={() => handleLoginstatus()}
                >
                  <BsPerson />
                  <span>{loginStatus ? "Logout" : "Login"}</span>
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      {modelToggle && <EnquiryForm modalClose={modalClose} />}
    </div>
  );
};

export default Header;
