import React, { useEffect, useState } from "react";
import Header from "./header";
import classes from "./layout.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../Redux/Actions/userActions";
import { Backdrop, CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import EnquiryForm from "../Pages/Others/enquiryForm";
import { setVisitorsCount } from "../Redux/Actions/otherActions";
import { SocialIcon } from "react-social-icons";
import CookieContainer from "../Components/CookieContainer";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modelToggle, setModelToggle] = useState(false);
  const [CookieModal, setCookieModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [countHit, setCountHit] = useState(false);
  const { loading } = useSelector((state) => state.userLogin);

  useEffect(() => {
    const count = localStorage.getItem("count_id");
    if (count) {
      return;
    } else {
      setVistCountFunction();
    }
  }, []);

  const setVistCountFunction = () => {
    if (!countHit) {
      const randomString =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      localStorage.setItem("count_id", randomString);
      const userEntry = {
        count_id: randomString,
      };
      dispatch(setVisitorsCount(userEntry, setErrorAlert));
      setCountHit(true);
    }
  };

  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    console.log("access token", accessToken);
  }, []);

  const checkNewUser = () => {
    const checkUser = sessionStorage.getItem("newUser");
    if (!checkUser) {
      setModelToggle(true);
    }
  };

  const CheckNewCookie = () => {
    const checkUser = localStorage.getItem("cookieCheck");
    if (!checkUser) {
      setCookieModal(true);
    }
  };

  useEffect(() => {
    //timout for 1 minute
    setTimeout(() => {
      checkNewUser();
    }, 60 * 1000);
  }, []);

  useEffect(() => {
    //timout for 1 minute
    setTimeout(() => {
      CheckNewCookie();
    }, 10 * 1000);
  }, []);

  const modalClose = async () => {
    setModelToggle(false);
    await sessionStorage.setItem("newUser", true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);
  return (
    <div style={{ position: "relative" }}>
      <div className={classes.defaultHeaderParentDiv}>
        <Header />
      </div>
      <div className={classes.defaultContentParentDiv}>
        <Outlet />
        {/* <FloatingWhatsApp
          phoneNumber={8778237578}
          accountName="Sri Varu Motors"
        /> */}
        <div className={classes.WaveContainer}>
          <SocialIcon
            href="https://wa.me/8098202030" // Replace 'yourphonenumber' with your phone number including country code
            url="https://web.whatsapp.com/" // This will not be used, but you can keep it if you want
            target="_blank"
            className={classes.WhatsAppBtn}
          />
        </div>
      </div>
      {!loading && <Footer />}
      {modelToggle && <EnquiryForm modalClose={modalClose} />}
      {/* {CookieModal && <CookieContainer modalClose={setCookieModal} />} */}
       <CookieContainer />
    </div>
  );
};

export default DefaultLayout;
